<template>
<div class="page-container">
	 <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>   
	<el-form ref="form" v-model="form" label-width='100px'>
		<el-tabs>
			 <el-tab-pane label="账号信息" >
				 <el-form-item label="商城域名" prop="">
				     <el-input  v-model="form.shop_domain"></el-input>
				 </el-form-item>
				 <el-form-item label="AppId" prop="">
				     <el-input  v-model="form.xcx_appid"></el-input>
				 </el-form-item>
				 <el-form-item label="App Secret" prop="">
				     <el-input  v-model="form.xcx_app_secret"></el-input>
				 </el-form-item>
			 </el-tab-pane>
			 <el-tab-pane label="页面路径" >
			 	<el-form-item v-for="(item,key) in form.path" :label="item.name" >
			 	    <el-input  v-model="item.url"></el-input>
			 	</el-form-item>	
			 </el-tab-pane>
		</el-tabs>
	</el-form>
	<div slot="footer" class="dialog-footer">
	   <el-button type="primary" @click="confirm()">确 定</el-button>
	</div>
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			// pathData:[],
			form:{
				id:'',
				shop_domain:'',
				xcx_appid:'',
				xcx_app_secret:'',
				path:[],
			}
        }
    },
    created() {
		this.onLoad();
    },
    mounted() {

    },
    methods:{
		onLoad(){
			this.$api.get('MiniProgram/index',null,res=>{
				if(res.code == 200){
					this.form =  res.data.account
				}
			})
		},
		confirm(){
			this.$api.put('MiniProgram/edit',this.form,res=>{
				if(res.code == 200){ 
					this.$message.success(res.msg) 
					this.reload()
				}
			})
		}
    },
}
</script>
<style scoped>

</style>